<template>
  <div class="projects">
    <div class="project" v-for="(project, i) in projects" :key="i">
      <!-- @mouseenter="$emit('updateimg', project.name)" -->
      <!-- <div @mouseenter="$emit('updateimg', project.name)">
        {{ project.title }}
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Projects",

  data: () => {
    return {
      active: false,
      projects: [
        {
          name: "cartesius",
          title: "Cartesius",
          subTitle: "3D butterfly experience",
          url: "https://cartesius.onrender.com/",
        },
        {
          name: "store",
          title: "3D phone store",
          subTitle: "Walk around phone store experience",
          url: "https://in3d-website.onrender.com/",
        },
        {
          name: "noiser",
          title: "Noiser",
          subTitle: "3D Purim holiday Grogger/Raashan",
          url: "https://noiser.xyz",
        },
        {
          name: "conways",
          title: "Conways 3D/2D",
          subTitle: "Conway with a taste of 3D or reactively switch to 2D",
          url: "https://conway.onrender.com/",
        },
        {
          name: "factory",
          title: "Factory tour",
          subTitle: "360 degrees tour of a factory",
          url: "https://icl-meabeh.onrender.com/",
        },
        {
          name: "orb",
          title: "Orb Forge",
          subTitle: "Tool for building 360 tours and stories",
          url: "https://orb.onrender.com/",
        },
        {
          name: "chill",
          title: "Three Chill",
          subTitle: "3D Multiplayer camping experience",
          url: "https://three-chill.onrender.com/",
        },
        {
          name: "shoe",
          title: "Shoe Customizer",
          subTitle: "3D shoe customizer mini experience",
          url: "https://pack-a-shoe.onrender.com/",
        },
        {
          name: "dash",
          title: "store customizer",
          subTitle: "Allows store admin to customise stores textures",
          url: "",
        },

        {
          name: "forge",
          title: "CSS animated logo",
          subTitle: "Pure css animated logo on CodePen",
          url: "https://codepen.io/CODEpenetration/pen/bGWwQPN",
        },

        {
          name: "reverse",
          title: "Text Reverse",
          subTitle:
            "For all of the annoying software that cannot handle RTL properly.",
          url: "https://pack-a-shoe.onrender.com/",
        },
        {
          name: "brew",
          title: "Ariel's Brew",
          subTitle: "A portfolio i made a while back.",
          url: "https://pack-a-shoe.onrender.com/",
        },
        {
          name: "lanomaly",
          title: "Lanomaly",
          subTitle: "Anomaly detection control panel",
          url: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    loge: function (e) {
      console.log("tadfa");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.projects {
  position: absolute;
  // bottom: 35px;
  left: 6%;
}
p {
  cursor: pointer;
}
</style>
