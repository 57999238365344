<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fuggles&display=swap");

* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  // background: #e0ebe8;
  // background: #e3d7c0;
  // background: #e2e1e2;
  // background: #ccacac;
  background: #abbbd6;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #3e9c87;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
}

// root variables overwrite
:root {
  --vs-background: 30, 32, 35;
  --vs-text: 255, 255, 255;
  --vs-gray-1: 24, 25, 28;
  --vs-gray-2: 20, 20, 23;
  --vs-gray-3: 15, 16, 19;
  --vs-gray-4: 10, 11, 14;
  --vs-shadow-opacity: 0.3;
  --vs-dark: 0, 0, 0;
  --vs-background-opacity: 0.6;
}

// scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: block;
  background: var(--vs-theme-layout);
}
::-webkit-scrollbar-thumb {
  background: #3e9c87;
  border-radius: 5px !important;
}

// general css overwrite
.vs-card {
  min-width: unset;
  width: 250px;
  @media only screen and (max-width: 600px) {
    width: 150px;
  }
}
.vs-card__group .vs-card__group-cards .vs-card-content {
  min-width: 250px;
  @media only screen and (max-width: 600px) {
    min-width: 150px;
  }
}
.vs-card-content.type-5 .vs-card {
  background: transparent;
}
.vs-card__group .vs-card__group-next,
.vs-card__group .vs-card__group-prev {
  @media only screen and (max-width: 600px) {
    min-width: 22px;
    min-height: 22px;
    height: 22px;
    width: 22px;
  }
}
.vs-card__group .vs-card__group-next .vs-icon-arrow,
.vs-card__group .vs-card__group-prev .vs-icon-arrow {
  width: 25px;
  height: 25px;
  @media only screen and (max-width: 600px) {
    width: 10px;
    height: 10px;
  }
}
</style>
