<template>
  <div class="main" dark>
    <Scene :sphereImg="sphereImg" />
    <Projects @updateimg="sphereImg = $event" />
    <h1>{{ sphereImg }}</h1>
  </div>
</template>

<script>
import Scene from "@/components/Scene.vue";
import Projects from "@/components/Projects.vue";
export default {
  name: "Main",
  components: { Scene, Projects },
  mounted() {},
  data: () => {
    return { sphereImg: "" };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.main {
  display: flex;
  flex-direction: column;
  position: relative;
}
.scene {
  height: 100vh;
  width: 100%;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
