<template>
  <!-- sect 1 -->
  <a-entity position="0 0 0" class="machine">
    <a-entity class="pipe">
      <a-torus
        position="0 -2 2.3"
        radius-tubular="0.28"
        rotation="0 90 0"
        radius="2"
        material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.35;"
      >
      </a-torus>
      <a-gltf-model
        scale="0.6 0.8 0.6"
        rotation="90 0 0"
        position="0 0 -2"
        src="models/minimal/pipe_rim.glb"
      ></a-gltf-model>
      <a-gltf-model
        scale="0.6 0.8 0.6"
        rotation="90 0 0"
        position="0 0 2"
        src="models/minimal/pipe_rim.glb"
      ></a-gltf-model>
      <a-gltf-model
        scale="0.6 0.8 0.6"
        rotation="0 0 0"
        position="0 -2 4.3"
        src="models/minimal/pipe_rim.glb"
      ></a-gltf-model>
    </a-entity>
    <a-gltf-model
      src="models/minimal/machine1.glb"
      @model-loaded="machineLoaded"
    >
      <!-- fans -->
      <a-gltf-model
        scale="0.6 0.6 0.6"
        position="0.8 2.1 0.8"
        src="models/minimal/fan_rim.glb"
        ><a-gltf-model
          scale="0.17 0.17 0.17"
          rotation="-90 0 0"
          position="0 0 0"
          src="models/fan_blades.glb"
          animation__rot="property: rotation; to: -90 0 360; loop: true; dur: 300; easing:linear"
        ></a-gltf-model
      ></a-gltf-model>
      <a-gltf-model
        scale="0.6 0.6 0.6"
        position="-0.8 2.1 -0.8"
        src="models/minimal/fan_rim.glb"
        ><a-gltf-model
          scale="0.17 0.17 0.17"
          rotation="-90 0 0"
          position="0 0 0"
          src="models/fan_blades.glb"
          animation__rot="property: rotation; to: -90 0 360; loop: true; dur: 300; easing:linear"
        ></a-gltf-model
      ></a-gltf-model>
      <a-gltf-model
        scale="0.6 0.6 0.6"
        position="-0.8 2.1 0.8"
        src="models/minimal/fan_rim.glb"
        ><a-gltf-model
          scale="0.17 0.17 0.17"
          rotation="-90 0 0"
          position="0 0 0"
          src="models/fan_blades.glb"
          animation__rot="property: rotation; to: -90 0 360; loop: true; dur: 300; easing:linear"
        ></a-gltf-model
      ></a-gltf-model>
      <a-gltf-model
        scale="0.6 0.6 0.6"
        position="0.8 2.1 -0.8"
        src="models/minimal/fan_rim.glb"
        ><a-gltf-model
          scale="0.17 0.17 0.17"
          rotation="-90 0 0"
          position="0 0 0"
          src="models/fan_blades.glb"
          animation__rot="property: rotation; to: -90 0 360; loop: true; dur: 300; easing:linear"
        ></a-gltf-model
      ></a-gltf-model>
    </a-gltf-model>
  </a-entity>
</template>
<script>
export default {
  name: "Red",
  data: () => {
    return {};
  },

  methods: {
    machineLoaded: function (e) {
      console.log(e.target.object3D.children.find(({name}) => name === 'Scene').children[0]);
    },
  },
};
</script>
