import Vue from 'vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
// import 'boxicons'

import App from './App.vue'
Vue.use(Vuesax, {
  // colors: {
  //   primary: '#5b3cc4',
  //   success: 'rgb(23, 201, 100)',
  //   danger: 'rgb(242, 19, 93)',
  //   warning: 'rgb(255, 130, 0)',
  //   dark: 'rgb(36, 33, 69)'
  // }
}
)

Vue.config.productionTip = false

Vue.config.ignoredElements = [
  'model-viewer',
  'a-scene',
  'a-asset-item',
  'a-image',
  'a-curvedimage',
  'a-assets',
  'a-text',
  'a-plane',
  'a-cylinder',
  'a-rounded',
  'a-light',
  'a-entity',
  'a-camera',
  'a-box',
  'a-sky',
  'a-cursor',
  'a-gltf-model',
  'a-ocean',
  'a-triangle',
  'a-circle',
  'a-cubemap'
]

new Vue({
  render: h => h(App),
}).$mount('#app')
