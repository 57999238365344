<template>
  <!-- sect 1 -->
    <!-- <a-gltf-model @model-loaded="modelLoaded" animation-mixer="clip: ZombieWalk" src="models/zombie.glb" scale="0.3 0.3 0.3" position="3.7 0.5 1.2"></a-gltf-model> -->
      <a-entity class="dome" position="-16 -4 0" scale="2 2 2">
        <a-entity
          position="-0.2 4 0"
          rotation="0 110 0"
          geometry="primitive: sphere; radius:1.2; width: 5; height: 3; thetaLength: 180;"
          material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.2;"
          phi-length="90"
        >
          <a-cylinder
            open-ended="true"
            position="0 -1 0"
            radius="1.21"
            height="2"
            material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.2;"
          >
          </a-cylinder>
        </a-entity>

        <!-- glass dome -->
        <a-gltf-model
          scale="1.4 1.4 1.4"
          position="0 3 0"
          src="models/minimal/base_for_glass.glb"
        >
        </a-gltf-model>
          <a-cylinder
            ref="progress"
            position="0 3.3 0"
            radius-tubular="0.03"
            color="#28f9cb"
            height="0.2"
            segments-radial="22"
            radius="1.21"
            open-ended="true"
            side="double"
            animation__progress="startEvents: start-progress; property: theta-length; from: 360; to: 0; dur: 7000; dir: alternate"
            @animationcomplete__progress="turntableDown"
          ></a-cylinder>


        <a-entity
          ref="turntable"
          class="turntable"
          position="0 3.4 0.1"
          rotation="0 90 0"
          animation__minimize="startEvents: start-minimize; property: scale; to: 0 0 0;  dur: 1400;"
          animation__maximize="startEvents: start-maximize; property: scale; from: 0 0 0; to: 1.1 1.1 1.1;  dur: 1400; "
          @animationcomplete__minimize="switchModel"
          @animationcomplete__maximize="startProgress"
        >
          <a-gltf-model
            @model-loaded="modelLoaded"
            :animation-mixer="`clip: ${models[modelNum].anim}`"
            :src="`models/turntable/${models[modelNum].name}.glb`"
            :scale="models[modelNum].scale"
            position="0 0 -0.4"
          ></a-gltf-model>
        </a-entity>
  </a-entity>
</template>
<script>
export default {
  name: "Yellow",
  data: () => {
    return {
      modelNum: 0,
      models: [
        {
          name: "pug",
          anim: "WalkSlow",
          scale: "0.3 0.3 0.3",
          color: "#d5a15b",
        },
        {
          name: "zombie",
          anim: "ZombieWalk",
          scale: "0.2 0.2 0.2",
          color: "#cde856",
        },
        {
          name: "llama",
          anim: "WalkSlow",
          scale: "0.2 0.2 0.2",
          color: "#cde856",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    modelLoaded: function (e) {
      this.$refs.turntable.emit("start-maximize");
    },
    startProgress: function (e) {
      console.log(this.$refs.progress);
      this.$refs.progress.emit("start-progress");
    },
    switchModel: function (e) {
      if (this.modelNum === this.models.length - 1) {
        this.modelNum = 0;
      } else {
        this.modelNum++;
      }
    },
    turntableDown: function (e) {
      let turntable = this.$refs.turntable;
      turntable.emit("start-minimize");
    },
  },
};
</script>