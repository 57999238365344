<template>
  <div class="scene">
    <div class="main-text">
      <h1>I'm Ariel.</h1>
      <h2>a 3D web developer.</h2>
    </div>
    <a-scene vr-mode-ui="enabled: false" stat>
      <!-- lights -->
      <a-entity light="type: ambient; color: #BBB"></a-entity>
      <a-entity
        light="intensity: 0.4; "
        position="7 3 5"
        rotation="0 0 -13.03"
      ></a-entity>
      <!-- camera -->
      <a-entity
        wasd-controls
        camera="fov: 50;"
        look-controls="enabled: true;"
        position="0 5 0"
      ></a-entity>

      <a-cubemap id="sky">
        <img src="img/map/nx.webp" />
        <img src="img/map/ny.webp" />
        <img src="img/map/nz.webp" />
        <img src="img/map/px.webp" />
        <img src="img/map/py.webp" />
        <img src="img/map/pz.webp" />
      </a-cubemap>

      <!-- <a-gltf-model src="models/test.glb" position="-1 0 -6" scale="2 2 2"><a-light type="point" color=green></a-light> <a-curvedimage scale="2 2 2"></a-curvedimage></a-gltf-model> -->
      <!-- https://aframe.io/docs/1.2.0/components/material.html#environment-maps -->

      <a-entity rotation="0 -90 -10" position="-4 0 -14">
        <a-plane
          color="#abbbd6"
          roughness="0.5"
          metalness="0.2"
          rotation="-90 0 0"
          position="0 -2 0"
          width="50"
          height="50"
          shader="flat"
        ></a-plane>

        <Computer />

        <Yellow />

        <Red />

        <!-- <a-box scale="10 10 10" material="envMap: #sky; roughness: 0; "></a-box>
        <a-cylinder scale="10 10 10" color="#28f9cb" position="0 0 -20" material="envMap: #sky; roughness: 0; "></a-cylinder> -->

        <a-entity class="dome" position="-16 -4 -11" scale="2 2 2">
          <a-cylinder
            height="5"
            radius="0.5"
            rotation="90 0 0"
            position="0 1.5 4"
            material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.35;"
          >
            <a-box v-for="n in 4" :key="n"
              color="#28f9cb"
              scale="0.2 0.2 0.2"
              :animation="`property: position; from: 0 -5 ${n * 0.05}; to: 0 2 ${n * 0.05}; loop: true; dur: ${Math.random() * 5400}; dir: alternate`"
            ></a-box>
          </a-cylinder>

          <a-entity
            position="-0.2 4 0"
            rotation="0 110 0"
            geometry="primitive: sphere; radius:1.2; width: 5; height: 3; thetaLength: 180;"
            material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.2;"
            phi-length="90"
          >
            <a-cylinder
              open-ended="true"
              position="0 -1 0"
              radius="1.21"
              height="2"
              material="envMap: #sky; roughness: 0; transparent: true; opacity: 0.2;"
            >
            </a-cylinder>
          </a-entity>

          <!-- glass dome -->
          <a-gltf-model
            scale="1.4 1.4 1.4"
            position="0 3 0"
            src="models/minimal/base_for_glass.glb"
          >
            <!-- floating cubes -->
            <a-entity
              class="floating-cubes"
              animation__rot="property: rotation; to: 0 360 0; loop: true; dur: 10400; dir: alternate"
            >
              <a-box
                position="0.2 0.3 0"
                rotation="0 0 10"
                color="#28f9cb"
                scale="0.2 0.2 0.2"
                animation="property: position; to: 0.4 0.4 0.2; loop: true; dur: 1400; dir: alternate"
                animation__rot="property: rotation; to: 0 0 90; loop: true; dur: 1400; dir: alternate"
              ></a-box>
              <a-box
                position="-0.3 0.3 0"
                rotation="-70 40 -10"
                color="#28f9cb"
                scale="0.2 0.2 0.2"
                animation="property: position; to: -0.4 0.6 -0.2; loop: true; dur: 1400; dir: alternate"
                animation__rot="property: rotation; to: -70 -90 -10; loop: true; dur: 1400; dir: alternate"
              ></a-box>
              <a-box
                position="0 0.8 -0.2"
                rotation="-70 40 -10"
                color="#28f9cb"
                scale="0.2 0.2 0.2"
                animation="property: position; to: 0 0.6 0; loop: true; dur: 1400; dir: alternate"
                animation__rot="property: rotation; to: -180 80 -10; loop: true; dur: 1400; dir: alternate"
              ></a-box>
            </a-entity>
          </a-gltf-model>
        </a-entity>
      </a-entity>
    </a-scene>
  </div>
</template>

<script>
import Red from "@/components/sections/Red.vue";
import Computer from "@/components/sections/Computer.vue";
import Yellow from "@/components/sections/Yellow.vue";
export default {
  name: "Scene",
  data: () => {
    return {};
  },
  components: {
    Red,
    Computer,
    Yellow,
  },
  props: ["projectsOpen", "sphereImg"],
  methods: {
    pugLoaded: function (e) {
      console.log(e.target.object3D.children[0].animations);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.scene {
  position: relative;
}
.vs-icon-arrow {
  width: 25px;
  height: 25px;
  transform: rotate(-135deg);
  display: block;
  color: #3e9c87;
}
.main-text {
  position: absolute;
  left: 50%;
  top: 15%;
  z-index: 4;
  transform: translateX(-50%);
  color: #3e9c87;
}

h1 {
  margin: 0;
  font-family: "Fuggles", cursive;
  text-align: center;
  font-size: 6rem;
}
h2 {
  font-size: 2rem;
  margin: 0;
}
</style>
